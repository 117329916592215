var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("ລວມແຜນ "+_vm._s(_vm.pagination.total))])]),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"Name, Description","type":"text","prepend-inner-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.Search()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"btn-primary",attrs:{"fab":"","small":"","dark":""},on:{"click":function($event){return _vm.createPlan()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"disable-pagination":true,"hide-default-footer":"","headers":_vm.headers,"items":_vm.routePlans,"items-per-page":25,"loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{style:(_vm.setPlanColor(item.color_code)),attrs:{"label":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36px"}},[(item.icon)?_c('img',{attrs:{"src":item.icon}}):_vm._e()])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewPlan(item.id)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editPlan(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.exportPdf(item)}}},[_vm._v(" mdi-download")])]}}])}),_c('br'),[(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchData()}}}):_vm._e()]],2)],1),_c('ModalDelete',[[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.deletePlanConfirm()}}},[_vm._v("OK ")]),_c('v-spacer')],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }