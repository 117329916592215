//import Validate from "@/plugins/Validation/Branch/branch"
import queryOption from "../../Helpers/queryOption";
import {GetOldValueOnInput} from "../../Helpers/GetValue";

export default {
    data() {
        return {
            //Pagination
            offset: 15,
            pagination: {},
            per_page: 50,

            loading: false,
            TableLoading: false,
            Id: '',
            server_errors: {
            },
            routePlans: [],
            search: '',
            headers: [
                {
                    text: "Name",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                {text: "Description", value: "description"},
                {text: "Icon", value: "icon"},
                {text: "Created", value: "created_at"},
                {text: "Actions", value: "actions", sortable: false},
            ],

            toast: {
                value: true,
                color: 'success',
                msg: 'Success'
            },
            toast_error: {
                value: true,
                color: 'error',
                msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        createPlan() {
            this.$router.push({name: 'CreatePlan'});
        },

        editPlan(id) {
            this.$router.push({name: 'EditPlan', params: {id: id}});
        },
        fetchData() {
            this.TableLoading = true;
            this.$admin.get('route-plan',{
                params: queryOption([
                    { page: this.pagination.current_page },
                    { per_page: this.per_page },
                    {   search: this.search}
                ]),
            }).then(res => {
                setTimeout(() => {
                    this.routePlans = res.data.data.data;
                    this.pagination = res.data.data;
                    this.TableLoading = false;
                }, 100);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },
        deleteItem(id) {
            this.Id = id;
            this.$store.commit("modalDelete_State", true);
        },
        deletePlanConfirm() {
            this.loading = true;
            this.$admin.delete('route-plan/' + this.Id).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.fetchData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 1000);
                }
            }).catch(() => {
                this.$store.commit("modalDelete_State", false);
                this.loading = true;
                this.$store.commit("Toast_State", this.toast_error);
            })
        },
        exportPdf(item) {
            this.$store.commit("progressLoading_State", true);
                this.$axios
                    .get(
                        "export/route-plan/" + item.id, {responseType: "blob"}
                    )
                    .then((res) => {
                        setTimeout(() => {
                            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                            const fileLink = document.createElement("a");
                            fileLink.href = fileUrl;
                            fileLink.setAttribute(
                                "download",
                                `${item.name}` +
                                ".xlsx"
                            );
                            document.body.appendChild(fileLink);
                            fileLink.click();
                            document.body.removeChild(fileLink);
                            this.$store.commit("progressLoading_State", false);
                        }, 100);
                    })
                    .catch(() => {
                        this.$store.commit("progressLoading_State", false);
                        this.$store.commit("Toast_State", this.toast_error);
                    });
        },
        Search() {
            GetOldValueOnInput(this);
        },
    },
    watch: {
        'search': function (value) {
            if (value == '') {
                this.fetchData();
            }
        },
    },
    created() {
        this.fetchData();
    },
}
