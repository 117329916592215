<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h2>ລວມແຜນ {{pagination.total}}</h2>
          </v-col>
          <v-col>
            <v-text-field
                outlined
                dense
                clearable
                label="Name, Description"
                type="text"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                @keyup.enter="Search()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
            <div class="my-2">
              <v-btn class="btn-primary" fab small dark @click="createPlan()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
        </v-row>

        <v-data-table
            :disable-pagination="true"
            hide-default-footer
            :headers="headers"
            :items="routePlans"
            :items-per-page="25"
            class="elevation-0"
            :loading="TableLoading"
            :disabled="TableLoading"
            loading-text="Loading... Please wait"
            :search="search"
        >
          <template v-slot:item.name="{ item }">
            <v-chip label :style="setPlanColor(item.color_code)">
              {{ item.name }}
            </v-chip>
<!--            <div>-->
<!--              {{ item.name }}-->
<!--            </div>-->
          </template>
          <template v-slot:item.icon="{item}">
            <v-avatar
                size="36px"
            >
              <img v-if="item.icon" :src="item.icon"/>
            </v-avatar>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div>
              {{ item.created_at | formatDate }}
            </div>
          </template>
          <!--Action -->
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="viewPlan(item.id)">
              mdi-eye
            </v-icon>
            <v-icon small class="mr-2" @click="editPlan(item.id)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item.id)" class="mr-2"> mdi-delete</v-icon>
            <v-icon small @click="exportPdf(item)"> mdi-download</v-icon>
          </template>
        </v-data-table>

        <br />
        <template>
          <Pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="fetchData()"
          ></Pagination>
        </template>
      </v-card-text>
    </v-card>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deletePlanConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
import manage from "../../../mixins/routeplan/manage";
import Pagination from "../../../plugins/pagination/pagination";

export default {
  mixins: [manage],
  components: {
    Pagination,
  },
  metaInfo() {
    return {
      title: `ແຜນຈັດສົ່ງ`,
    }
  },
  methods:{
    editPlan(id) {
      this.$router.push({name: 'EditPlan', params: {id: id}});
    },
    setPlanColor(value){
      let color = 'background-color:#' + value + ' !important;';
      return color;
    },
    viewPlan(id) {
      this.$router.push({name: 'ViewPlan', params: {id: id}});
    },
  }
};
</script>

<style>
</style>